.container {
  align-self: flex-start;
  border-radius: 5px;
  // width: max-content;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  outline: none;

  & p {
    margin: 0;
    font-weight: 600;
    padding: 9px var(--spacing-base);
    font-size: var(--text-base);
    width: max-content;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;

    & svg {
      margin-right: 0.5rem;
    }
  }
}

.container:hover {
  text-decoration: none !important;
  color: var(--white);
  transform: scale(1.1);
  transition: all 200ms;
}
.border {
  background-color: var(--lightGrey);
  border: solid 2px;
}
.fill {
  color: var(--white) !important;
  border: solid 2px;
  &.hoverBlue:hover {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-light) !important;
  }
  &.hoverRed:hover {
    background-color: var(--lightRed) !important;
    border-color: var(--lightRed) !important;
  }
}
.container:hover {
  text-decoration: none !important;
  color: var(--white);
  filter: brightness(90%);
}
