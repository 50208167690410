.NotFoundContainer {
  min-height: calc(100vh - 51px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NotFoundLogo {
  width: 200px;
  max-width: 80vw;
  margin-bottom: 50px;
}
.NotFoundTitle {
  font-size: var(--text-6xl);
  margin: 0;
  font-weight: 700;
}
.NotFoundSubTitle {
  font-size: var(--text-3xl);
  margin: 0;
  font-weight: 400;
  margin-bottom: 50px;
}
